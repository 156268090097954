import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Container } from "reactstrap"
import Layout from "../../components/layout"
import {
  Banner,
  BannerStyle,
  Content,
  PaddingHalfWrapper,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"

export const BankInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 500px;

  .inner {
    background-color: ${(props) => props.theme.color.white};
    padding: 1em;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 10px;
  }

  .key {
    text-align: right;
    white-space: nowrap;
  }

  .value {
    text-align: left;
    word-break: break-word;
  }
`

const BankovniSpojeni: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Bankovní spojení | igloonet</title>
    </Helmet>
    <Banner
      style={BannerStyle.Reference}
      orangeHeading="Bankovní spojení"
      text="Pro vaše pohodlí je k&nbsp;dispozici hned několik bankovních účtů."
    />

    <PaddingWrapper>
      <Container>
        <h2>Platby z Česka</h2>

        <div>
          <div className="font-weight-bold">Raiffeisenbank a.s.</div>
          <Content noPadding>
            <ul>
              <li>
                <b>CZK i EUR: </b> <code>7261610001/5500</code>
              </li>
            </ul>
          </Content>
        </div>

        <p className="ml-3">nebo</p>

        <div>
          <div className="font-weight-bold">Fio banka, a.s.</div>
          <Content noPadding>
            <ul>
              <li>
                <b>CZK: </b> <code>2500090814/2010</code>
              </li>
              <li>
                <b>EUR: </b> <code>2600104558/2010</code>
              </li>
            </ul>
          </Content>
        </div>

        <PaddingHalfWrapper>
          <h2 className="mt-5">Mezinárodní platby - pouze EUR</h2>
          <Content noPadding>
            <ul>
              <li>
                <b>Země EU: </b>
                platit lze{" "}
                <b>pouze v řežimu SEPA s dispozicí SHA, tzv. Europlatba</b>.
              </li>
              <li>
                <b>Ostatní země: </b>
                platit lze <b>pouze s dispozicí SHA</b>.
              </li>
            </ul>
            <ul>
              <li>
                Variabilní symbol zadávejte prosím do pole{" "}
                <b>Reference platby</b> a také do <b>Zprávy pro příjemce</b>.
              </li>
            </ul>
          </Content>

          <BankInfo>
            <div className="inner">
              <div className="key">Název banky:</div>
              <div className="value">
                <b>Fio banka, a.s.</b>,<br />
                <em>
                  V Celnici 1028/10,
                  <br />
                  Praha 1,
                  <br />
                  117 21,
                  <br />
                  Czech Republic (CZ)
                </em>
              </div>
              <div className="key">IBAN:</div>
              <div className="value">
                <code>CZ7720100000002600104558</code>
              </div>
              <div className="key">BIC/SWIFT:</div>
              <div className="value">
                <code>FIOBCZPPXXX</code>
              </div>
            </div>
          </BankInfo>

          <p className="mt-3 ml-3">nebo</p>

          <BankInfo>
            <div className="inner">
              <div className="key">Název banky:</div>
              <div className="value">
                <b>Raiffeisenbank a.s.</b>,<br />
                <em>Prague, Czech republic</em>
              </div>
              <div className="key">IBAN:</div>
              <div className="value">
                <code>CZ4055000000007261610001</code>
              </div>
              <div className="key">BIC/SWIFT:</div>
              <div className="value">
                <code>RZBCCZPP</code>
              </div>
            </div>
          </BankInfo>
        </PaddingHalfWrapper>
      </Container>
    </PaddingWrapper>
  </Layout>
)

export default BankovniSpojeni
